import React from 'react';
import { withErrorBoundary } from '@/utils/errors';
import classNames from 'classnames';
import styles from '@/pages/contacts/advanced-search/AdvancedSearch.module.scss';
import Select from 'react-select';

/**
 * The MultiInput Functional Component
 * @typedef Props:
 * @property {function(string)} value -
 * @property {function(string)} type -
 * @property {function(event, additionalParams)} changeBaseInput - change input value
 * @property {function(changeObj, event)} changeType - change select value
 * @property {Array)} options - Array with object for select options
 * @typedef TextFieldProps
 * @property {string} label - label and placeholder input
 * @property {string} param - params
 */

const AdvancedSearchItem = ({
  param,
  label,
  value,
  changeBaseInput,
  submitOnEnter,
  type,
  changeType,
  options,
  placeholder = '',
  attributeType = 'text',
  classes
}) => {
  return (
    <div className={classNames(`field ${classes || param.replace(/[^A-Za-z]/g, '')}`, styles.field)}>
      <div className={param}>
        <p className={classNames('title', styles.title)}>{label}</p>
        <div className={styles.selectFields}>
          <input
            autoComplete="new-password"
            maxLength="999"
            name={param}
            disabled={type(param) === 'empty' || type(param) === 'not_empty'}
            className="input is-large"
            type={attributeType}
            placeholder={placeholder || label}
            value={value(param)}
            onChange={changeBaseInput}
            onKeyDown={submitOnEnter}
          />
          <Select
            name={param}
            className={classNames('custom-select', styles.customSelect)}
            classNamePrefix="custom-select"
            value={options.find(el => el.value === type(param))}
            options={options}
            onChange={changeType}
          />
        </div>
      </div>
    </div>
  );
};

export default withErrorBoundary(AdvancedSearchItem);
