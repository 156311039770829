export const dataNetFields = [
  { label: 'Net Face Amount (WTD)', param: 'net_face_amount_wtd' },
  { label: 'Net Face Amount (MTD)', param: 'net_face_amount_mtd' },
  { label: 'Net Face Amount (QTD)', param: 'net_face_amount_qtd' },
  { label: 'Net Face Amount (YTD)', param: 'net_face_amount_ytd' },
  { label: 'Net Policy Count (WTD)', param: 'net_policy_count_wtd' },
  { label: 'Net Policy Count (MTD)', param: 'net_policy_count_mtd' },
  { label: 'Net Policy Count (QTD)', param: 'net_policy_count_qtd' },
  { label: 'Net Policy Count (YTD)', param: 'net_policy_count_ytd' },
  { label: 'Gross Face Amount (WTD)', param: 'gross_face_amount_wtd' },
  { label: 'Gross Face Amount (MTD)', param: 'gross_face_amount_mtd' },
  { label: 'Gross Face Amount (QTD)', param: 'gross_face_amount_qtd' },
  { label: 'Gross Face Amount (YTD)', param: 'gross_face_amount_ytd' },
  { label: 'Gross Policy Count (WTD)', param: 'gross_policy_count_wtd' },
  { label: 'Gross Policy Count (MTD)', param: 'gross_policy_count_mtd' },
  { label: 'Gross Policy Count (QTD)', param: 'gross_policy_count_qtd' },
  { label: 'Gross Policy Count (YTD)', param: 'gross_policy_count_ytd' }
];
