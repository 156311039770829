import { useState } from 'react';
import { useClickOutside } from '@/hooks/useClickOutside';
import classNames from 'classnames';
import styles from './ActiveBadge.module.scss';

export function ActiveBadgeItem({ enable, remove, activeOption, children }) {
  const [isOpen, setIsOpen] = useState(false);

  const onToggle = () => {
    setIsOpen(open => !open);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const onEnable = async activeOption => {
    onClose();
    await enable(activeOption);
  };

  const onRemove = async activeOption => {
    onClose();
    await remove(activeOption);
  };

  const ref = useClickOutside(onClose);

  return (
    <div className={styles.container} ref={ref}>
      <div
        className={classNames('search-option', {
          disabled: activeOption?.disabled
        })}
        onClick={onToggle}
      >
        {children}
      </div>
      {isOpen && (
        <div className={classNames('dropdown-content', styles.optionContent)}>
          <button onClick={async () => await onEnable(activeOption)} className={styles.optionItem}>
            {activeOption?.disabled ? 'Enable' : 'Disable'}
          </button>
          <button onClick={async () => await onRemove(activeOption)} className={styles.optionItem}>
            Clear
          </button>
        </div>
      )}
    </div>
  );
}
