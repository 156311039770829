import React from 'react';
import { withErrorBoundary } from '@/utils/errors';
import classNames from 'classnames';
import styles from '@/pages/contacts/advanced-search/AdvancedSearch.module.scss';
import Select from 'react-select';
import { dataNetFields } from '@/utils/advance-search-data';
import AdvancedSearchItem from '@/components/Contacts/advanced-search/AdvancedSearchItem';
import AdvancedSearchItemDate from '@/components/Contacts/advanced-search/AdvancedSearchItemDate';

import { AFFINITY, RM_COMMISSIONS, RM_PRODUCTS, TYPE_OPTIONS } from '@/store/models/contact';
import { MAIN_OR_BRANCH } from '@/store/models/profile';

const RmAdvancedSearchItems = ({
  changeType,
  changeBaseInput,
  value,
  type,
  valueIncludeExclude,
  validOptions,
  sources,
  changeSelect,
  removeMultipleValues,
  addMultipleValue,
  removeMultipleValue,
  stateZip,
  homeAddressZip,
  changeDate,
  valueRangeStart,
  changeDateRangeStart,
  valueRangeEnd,
  changeDateRangeEnd,
  changeDateType
}) => {
  return (
    <>
      <hr />
      <AdvancedSearchItem
        label="TPM Name"
        param="tpm_name"
        value={value}
        type={type}
        changeType={changeType}
        changeBaseInput={changeBaseInput}
        options={TYPE_OPTIONS}
      />
      <AdvancedSearchItem
        label="Website"
        param="website"
        value={value}
        type={type}
        changeType={changeType}
        changeBaseInput={changeBaseInput}
        options={TYPE_OPTIONS}
      />
      <AdvancedSearchItem
        label="Business Address line"
        param="ba_address_line"
        value={value}
        type={type}
        changeType={changeType}
        changeBaseInput={changeBaseInput}
        options={TYPE_OPTIONS}
        classes="businessaddressline"
      />
      <AdvancedSearchItem
        label="Business Address line 2"
        param="ba_address_line_2"
        value={value}
        type={type}
        changeType={changeType}
        changeBaseInput={changeBaseInput}
        options={TYPE_OPTIONS}
        classes="businessaddressline"
      />
      <AdvancedSearchItem
        label="B.A. City"
        param="ba_city"
        value={value}
        type={type}
        changeType={changeType}
        changeBaseInput={changeBaseInput}
        options={TYPE_OPTIONS}
      />
      <AdvancedSearchItem
        label="B.A. State"
        param="ba_state"
        value={value}
        type={type}
        changeType={changeType}
        changeBaseInput={changeBaseInput}
        options={TYPE_OPTIONS}
      />
      <div className={classNames('field zipcode', styles.field)}>
        <div className="ba_zip_code">
          <p className={classNames('title', styles.title)}>B.A. Zip</p>
          <div className={styles.selectFields}>
            <input
              id="ba_zip_code"
              name="ba_zip_code"
              disabled={type('ba_zip_code') === 'empty' || type('ba_zip_code') === 'not_empty'}
              placeholder="B.A. Zip"
              maxLength={10}
              className="input is-large"
              onChange={e => changeBaseInput(e, { withZipCodeFormat: true })}
              value={value('ba_zip_code')}
            />
            <Select
              name="ba_zip_code"
              className={classNames('custom-select', styles.customSelect)}
              classNamePrefix="custom-select"
              value={TYPE_OPTIONS.find(el => el.value === type('ba_zip_code'))}
              options={TYPE_OPTIONS}
              onChange={(changedObj, e) => {
                changeType(changedObj, e);
                if (changedObj.value === 'empty' || changedObj.value === 'not_empty')
                  removeMultipleValues('ba_zip_code');
              }}
            />
            <button
              type="button"
              disabled={type('ba_zip_code') === 'empty' || type('ba_zip_code') === 'not_empty'}
              onClick={() => addMultipleValue('ba_zip_code')}
              className={classNames('button is-large is-secondary')}
            >
              + Add
            </button>
            <button
              type="button"
              disabled={type('ba_zip_code') === 'empty' || type('ba_zip_code') === 'not_empty'}
              onClick={() => removeMultipleValues('ba_zip_code')}
              className={classNames('button is-large is-secondary')}
            >
              Remove All
            </button>
          </div>
          {stateZip.map(type => {
            return (
              <div key={type.type}>
                <p>
                  {TYPE_OPTIONS.find(option => option.value === type.type).label}
                  {' :'}
                </p>
                <div className={styles.labelWrapper}>
                  {type?.value?.map(value => (
                    <p
                      key={type.type + value}
                      onClick={e => e.stopPropagation()}
                      style={{ backgroundColor: 'grey' }}
                      className={styles.labeled}
                    >
                      {value}
                      <i
                        onClick={() => removeMultipleValue('ba_zip_code', type.type, value)}
                        className="fas fa-times"
                      />
                    </p>
                  ))}{' '}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={classNames('field zipcode', styles.field)}>
        <div className="home_address_zip_code">
          <p className={classNames('title', styles.title)}>Home Address Zip Code</p>
          <div className={styles.selectFields}>
            <input
              id="home_address_zip_code"
              name="home_address_zip_code"
              disabled={type('home_address_zip_code') === 'empty' || type('home_address_zip_code') === 'not_empty'}
              placeholder="Home Address Zip Code"
              maxLength={10}
              className="input is-large"
              onChange={e => changeBaseInput(e, { withZipCodeFormat: true })}
              value={value('home_address_zip_code')}
            />
            <Select
              name="home_address_zip_code"
              className={classNames('custom-select', styles.customSelect)}
              classNamePrefix="custom-select"
              value={TYPE_OPTIONS.find(el => el.value === type('home_address_zip_code'))}
              options={TYPE_OPTIONS}
              onChange={(changedObj, e) => {
                changeType(changedObj, e);
                if (changedObj.value === 'empty' || changedObj.value === 'not_empty')
                  removeMultipleValues('home_address_zip_code');
              }}
            />
            <button
              type="button"
              disabled={type('home_address_zip_code') === 'empty' || type('home_address_zip_code') === 'not_empty'}
              onClick={() => addMultipleValue('home_address_zip_code')}
              className={classNames('button is-large is-secondary')}
            >
              + Add
            </button>
            <button
              type="button"
              disabled={type('home_address_zip_code') === 'empty' || type('home_address_zip_code') === 'not_empty'}
              onClick={() => removeMultipleValues('home_address_zip_code')}
              className={classNames('button is-large is-secondary')}
            >
              Remove All
            </button>
          </div>
          {homeAddressZip.map(type => {
            return (
              <div key={type.type}>
                <p>
                  {TYPE_OPTIONS.find(option => option.value === type.type).label}
                  {' :'}
                </p>
                <div className={styles.labelWrapper}>
                  {type?.value?.map(value => (
                    <p
                      key={type.type + value}
                      onClick={e => e.stopPropagation()}
                      style={{ backgroundColor: 'grey' }}
                      className={styles.labeled}
                    >
                      {value}
                      <i
                        onClick={() => removeMultipleValue('home_address_zip_code', type.type, value)}
                        className="fas fa-times"
                      />
                    </p>
                  ))}{' '}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <AdvancedSearchItem
        label="Facebook"
        param="facebook"
        value={value}
        type={type}
        changeType={changeType}
        changeBaseInput={changeBaseInput}
        options={TYPE_OPTIONS}
      />
      <AdvancedSearchItem
        label="LinkedIn"
        param="linkedin"
        value={value}
        type={type}
        changeType={changeType}
        changeBaseInput={changeBaseInput}
        options={TYPE_OPTIONS}
      />
      <AdvancedSearchItem
        label="Twitter"
        param="twitter"
        value={value}
        type={type}
        changeType={changeType}
        changeBaseInput={changeBaseInput}
        options={TYPE_OPTIONS}
      />
      <AdvancedSearchItem
        label="Instagram"
        param="instagram"
        value={value}
        type={type}
        changeType={changeType}
        changeBaseInput={changeBaseInput}
        options={TYPE_OPTIONS}
      />
      <div className={classNames('field products', styles.field, styles.customIncludes)}>
        <div className="products">
          <p className={classNames('title', styles.title)}>Product</p>
          <div className={styles.includesWrapper}>
            <div className={styles.includeItem}>
              <p className={styles.description}>Include:</p>
              <Select
                name="products"
                isMulti
                className="custom-select with-include"
                classNamePrefix="custom-select"
                placeholder="Type to include..."
                value={valueIncludeExclude('products', 'include')}
                options={validOptions(RM_PRODUCTS, valueIncludeExclude('products', 'exclude'))}
                onChange={e => changeSelect(e, 'products', 'include')}
              />
            </div>
            <div className={styles.includeItem}>
              <p className={styles.description}>Exclude:</p>
              <Select
                name="products"
                isMulti
                className="custom-select with-include"
                classNamePrefix="custom-select"
                placeholder="Type to exclude..."
                value={valueIncludeExclude('products', 'exclude')}
                options={validOptions(RM_PRODUCTS, valueIncludeExclude('products', 'include'))}
                onChange={e => changeSelect(e, 'products', 'exclude')}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={classNames('field agencysource', styles.field, styles.customIncludes)}>
        <div className="agency_source_id">
          <p className={classNames('title', styles.title)}>Agency Source</p>
          <div className={styles.includesWrapper}>
            <div className={styles.includeItem}>
              <p className={styles.description}>Include:</p>
              <Select
                name="agency_source_id"
                isMulti
                className="custom-select with-include"
                classNamePrefix="custom-select"
                placeholder="Type to include..."
                value={valueIncludeExclude('agency_source_id', 'include')}
                options={validOptions(sources, valueIncludeExclude('agency_source_id', 'exclude'))}
                onChange={e => changeSelect(e, 'agency_source_id', 'include')}
              />
            </div>
            <div className={styles.includeItem}>
              <p className={styles.description}>Exclude:</p>
              <Select
                name="agency_source_id"
                isMulti
                className="custom-select with-include"
                classNamePrefix="custom-select"
                placeholder="Type to exclude..."
                value={valueIncludeExclude('agency_source_id', 'exclude')}
                options={validOptions(sources, valueIncludeExclude('agency_source_id', 'include'))}
                onChange={e => changeSelect(e, 'agency_source_id', 'exclude')}
              />
            </div>
          </div>
        </div>
      </div>
      <AdvancedSearchItem
        label="Fax Number"
        param="fax_number"
        value={value}
        type={type}
        changeType={changeType}
        changeBaseInput={changeBaseInput}
        options={TYPE_OPTIONS}
      />
      <AdvancedSearchItem
        label="Home Address City"
        param="home_address_city"
        value={value}
        type={type}
        changeType={changeType}
        changeBaseInput={changeBaseInput}
        options={TYPE_OPTIONS}
      />
      <AdvancedSearchItem
        label="Home Address State"
        param="home_address_state"
        value={value}
        type={type}
        changeType={changeType}
        changeBaseInput={changeBaseInput}
        options={TYPE_OPTIONS}
      />
      <AdvancedSearchItem
        label="Alt Phone"
        param="alt_phone"
        value={value}
        type={type}
        changeType={changeType}
        changeBaseInput={changeBaseInput}
        options={TYPE_OPTIONS}
      />
      <AdvancedSearchItem
        label="Phone (TPM and Funeral Home)"
        param="rm_phone"
        value={value}
        type={type}
        changeType={changeType}
        changeBaseInput={changeBaseInput}
        options={TYPE_OPTIONS}
        classes="phonetpmandfuneralhome"
      />
      <div className={classNames('field commissions', styles.field, styles.customIncludes)}>
        <div className="commissions">
          <p className={classNames('title', styles.title)}>Commissions</p>
          <div className={styles.includesWrapper}>
            <div className={styles.includeItem}>
              <p className={styles.description}>Include:</p>
              <Select
                isMulti
                name="commissions"
                className="custom-select with-include"
                classNamePrefix="custom-select"
                placeholder="Type to include..."
                value={valueIncludeExclude('commissions', 'include')}
                options={validOptions(RM_COMMISSIONS, valueIncludeExclude('commissions', 'exclude'))}
                onChange={e => changeSelect(e, 'commissions', 'include')}
              />
            </div>
            <div className={styles.includeItem}>
              <p className={styles.description}>Exclude:</p>
              <Select
                isMulti
                name="commissions"
                className="custom-select with-include"
                classNamePrefix="custom-select"
                placeholder="Type to exclude..."
                value={valueIncludeExclude('commissions', 'exclude')}
                options={validOptions(RM_COMMISSIONS, valueIncludeExclude('commissions', 'include'))}
                onChange={e => changeSelect(e, 'commissions', 'exclude')}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={classNames('field mainorbranch', styles.field, styles.customIncludes)}>
        <div className="main_or_branch">
          <p className={classNames('title', styles.title)}>Main or Branch</p>
          <div className={styles.includesWrapper}>
            <div className={styles.includeItem}>
              <p className={styles.description}>Include:</p>
              <Select
                isMulti
                name="main_or_branch"
                className="custom-select with-include"
                classNamePrefix="custom-select"
                placeholder="Type to include..."
                value={valueIncludeExclude('main_or_branch', 'include')}
                options={validOptions(MAIN_OR_BRANCH, valueIncludeExclude('main_or_branch', 'exclude'))}
                onChange={e => changeSelect(e, 'main_or_branch', 'include')}
              />
            </div>
            <div className={styles.includeItem}>
              <p className={styles.description}>Exclude:</p>
              <Select
                isMulti
                name="main_or_branch"
                className="custom-select with-include"
                classNamePrefix="custom-select"
                placeholder="Type to exclude..."
                value={valueIncludeExclude('main_or_branch', 'exclude')}
                options={validOptions(MAIN_OR_BRANCH, valueIncludeExclude('main_or_branch', 'include'))}
                onChange={e => changeSelect(e, 'main_or_branch', 'exclude')}
              />
            </div>
          </div>
        </div>
      </div>
      <AdvancedSearchItem
        label="Life Pro #"
        param="agency_lp_number"
        value={value}
        type={type}
        changeType={changeType}
        changeBaseInput={changeBaseInput}
        options={TYPE_OPTIONS}
        classes="lifepro"
      />
      <div className={classNames('field aml', styles.field, styles.customIncludes)}>
        <div className="aml">
          <p className={classNames('title', styles.title)}>AML</p>
          <div className={styles.includesWrapper}>
            <div className={styles.includeItem}>
              <p className={styles.description}>Include:</p>
              <Select
                name="aml"
                isMulti
                className="custom-select with-include"
                classNamePrefix="custom-select"
                placeholder="Type to include..."
                value={valueIncludeExclude('aml', 'include')}
                options={validOptions(AFFINITY, valueIncludeExclude('aml', 'exclude'))}
                onChange={e => changeSelect(e, 'aml', 'include')}
              />
            </div>
            <div className={styles.includeItem}>
              <p className={styles.description}>Exclude:</p>
              <Select
                name="aml"
                isMulti
                className="custom-select with-include"
                classNamePrefix="custom-select"
                placeholder="Type to exclude..."
                value={valueIncludeExclude('aml', 'exclude')}
                options={validOptions(AFFINITY, valueIncludeExclude('aml', 'include'))}
                onChange={e => changeSelect(e, 'aml', 'exclude')}
              />
            </div>
          </div>
        </div>
      </div>
      <AdvancedSearchItemDate
        title="AML Renewal Date"
        param="aml_renewal_date"
        changeDateType={changeDateType}
        changeDateRangeStart={changeDateRangeStart}
        valueRangeStart={valueRangeStart}
        type={type}
        changeDate={changeDate}
        value={value}
        valueRangeEnd={valueRangeEnd}
        changeDateRangeEnd={changeDateRangeEnd}
      />
      <AdvancedSearchItem
        label="License #"
        param="license"
        value={value}
        type={type}
        changeType={changeType}
        changeBaseInput={changeBaseInput}
        options={TYPE_OPTIONS}
      />
      <AdvancedSearchItemDate
        title="License Renewal Date"
        param="license_renewal_date"
        changeDateType={changeDateType}
        changeDateRangeStart={changeDateRangeStart}
        valueRangeStart={valueRangeStart}
        type={type}
        changeDate={changeDate}
        value={value}
        valueRangeEnd={valueRangeEnd}
        changeDateRangeEnd={changeDateRangeEnd}
      />
      {dataNetFields.map(field => (
        <AdvancedSearchItem
          key={field.param}
          label={field.label}
          param={field.param}
          value={value}
          type={type}
          changeType={changeType}
          changeBaseInput={changeBaseInput}
          options={TYPE_OPTIONS}
        />
      ))}
    </>
  );
};

export default withErrorBoundary(RmAdvancedSearchItems);
