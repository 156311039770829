import React from 'react';
import { withErrorBoundary } from '@/utils/errors';
import classNames from 'classnames';
import styles from '@/pages/contacts/advanced-search/AdvancedSearch.module.scss';
import DatePicker from 'react-datepicker';
import { parseDateUTC } from '@/utils/helpers';
import Select from 'react-select';
import { DATE_OPTIONS } from '@/store/models/contact';

const AdvancedSearchItemDate = ({
  title,
  param,
  changeDate,
  type,
  valueRangeEnd,
  valueRangeStart,
  changeDateRangeStart,
  changeDateRangeEnd,
  changeDateType,
  value
}) => {
  return (
    <div className={classNames(`field ${param.replace(/[^A-Za-z]/g, '')}`, styles.field)}>
      <div className={param}>
        <p className={classNames('title', styles.title)}>{title}</p>
        <div className={styles.dateFields}>
          {type(param) !== 'range' && (
            <DatePicker
              showYearDropdown
              showMonthDropdown
              dropdownMode="select"
              closeOnScroll={true}
              id={param}
              selected={parseDateUTC(value(param))}
              onChange={e => changeDate(e, param)}
              name={param}
              placeholderText="mm/dd/yyyy"
              dateFormat="MM/dd/yyyy"
              className={classNames('input is-large', styles.dateStart)}
              disabled={type(param) === 'all' || type(param) === 'none'}
            />
          )}

          {type(param) === 'range' && (
            <>
              <DatePicker
                showYearDropdown
                showMonthDropdown
                dropdownMode="select"
                closeOnScroll={true}
                selected={parseDateUTC(valueRangeStart(param))}
                onChange={e => changeDateRangeStart(e, param)}
                name={param}
                placeholderText="mm/dd/yyyy"
                dateFormat="MM/dd/yyyy"
                className={classNames('input is-large', styles.dateStart)}
              />
              <DatePicker
                showYearDropdown
                showMonthDropdown
                dropdownMode="select"
                closeOnScroll={true}
                minDate={parseDateUTC(valueRangeStart(param))}
                selected={parseDateUTC(valueRangeEnd(param))}
                onChange={e => changeDateRangeEnd(e, param)}
                name={param}
                disabled={!valueRangeStart(param)}
                placeholderText="mm/dd/yyyy"
                dateFormat="MM/dd/yyyy"
                className={classNames('input is-large', styles.dateEnd)}
              />
            </>
          )}
          <Select
            name={param}
            className={classNames('custom-select', styles.customSelect)}
            classNamePrefix="custom-select"
            value={DATE_OPTIONS.find(el => el.value === type(param))}
            options={DATE_OPTIONS}
            onChange={changeDateType}
          />
        </div>
      </div>
    </div>
  );
};

export default withErrorBoundary(AdvancedSearchItemDate);
