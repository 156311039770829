export const TYPES = [
  { value: 'imported', label: 'Imported' },
  { value: 'infofree', label: 'Infofree' },
  { value: 'other', label: 'Other' }
];

export const TYPES_FOR_ADMIN = [
  ...TYPES,
  { value: 'uploaded_lead', label: 'Uploaded Lead' },
  { value: 'new_business_file', label: 'New Business File' }
];
