import { memo } from 'react';

export const ActiveBadgeContent = memo(function ActiveSearchBadgeContent({
  label,
  content,
  withColon = true,
  isDisabled = false
}) {
  return (
    <p>
      <span className={isDisabled ? 'mbri-close' : 'mbri-success'} />
      <b>
        {label}
        {withColon && ':'}
      </b>
      {content}
    </p>
  );
});
